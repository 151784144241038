import { defaultAppConfig, rewritesApi } from 'src/environments';
import { environment } from 'src/environments/environment';
import { Apis, AppConfig, BrowserStorage, DefaultAppConfig } from '../models/config';

export class AppConfigProvider implements AppConfig {
    public production: boolean;
    public debug: boolean;
    public title: string;
    public browserStorage: BrowserStorage;
    public apiUrl: string;
    public apis: Apis;
    public gazelleUrl: string;
    public versionCheckInterval: number;
    public userStatsInterval: number;

    public constructor(config?: DefaultAppConfig | undefined) {
        const appConfig: DefaultAppConfig = {
            ...structuredClone(defaultAppConfig),
            ...environment,
            ...(config || {}),
            ...(Object.keys(rewritesApi)?.length && {
                apis: {
                    ...defaultAppConfig.apis,
                    ...rewritesApi
                }
            })
        };

        this.production = appConfig.production;
        this.debug = !!appConfig.debug;
        this.title = appConfig.title;
        this.versionCheckInterval = appConfig.versionCheckInterval;
        this.userStatsInterval = appConfig.userStatsInterval;
        this.apiUrl = appConfig.apiUrl;
        this.apis = appConfig.apis;
        this.browserStorage = appConfig.browserStorage;
        this.gazelleUrl = appConfig.gazelleUrl;
    }

    public getApiUrl(apiPath: string): string {
        if (!this.debug) {
            return `${this.apiUrl}${apiPath}`;
        }

        if (apiPath.indexOf('http://') === 0 || apiPath.indexOf('https://') === 0) {
            return apiPath;
        }

        return `${this.apiUrl}${apiPath}`;
    }
}
