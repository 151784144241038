<div class="line-full"></div>

<div class="footer-wrapper">

    <div class="disclaimer">
        None of the files shown here are actually hosted on this server. The links are provided solely by this
        site's users. These BitTorrent files are meant for the distribution of backup files. By downloading the
        BitTorrent file, you are claiming that you own the original file. The administrator of this site
        ( {{ siteUrl }} ) holds NO RESPONSIBILITY if these files are misused in any way and cannot be held
        responsible for what its users post, or any other actions of it.
    </div>

</div>

<footer>
    <div class="title">
        Site and design © 2022 BIT-HDTV
    </div>
</footer>

