import { Route } from '@angular/router';

import { LayoutGuard } from 'src/app/shared/guards/layout.guard';
import { LayoutType } from 'src/app/shared/models/routes';
import { NoLayoutComponent } from '../layouts/no-layout/no-layout.component';
import { PrivateLayoutComponent } from '../layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from '../layouts/public-layout/public-layout.component';

export const LayoutsRoutes: Record<LayoutType, Route> = Object.freeze({
    private: Object.freeze({
        path: '',
        component: PrivateLayoutComponent,
        canMatch: [LayoutGuard],
        data: { type: 'layout', permission: 'logged' },
        children: []
    }),
    public: Object.freeze({
        path: '',
        component: PublicLayoutComponent,
        canMatch: [LayoutGuard],
        data: { type: 'layout', permission: 'public' },
        children: []
    }),
    noLayout: Object.freeze({
        path: 'e',
        component: NoLayoutComponent,
        data: { type: 'layout', permission: 'both' },
        children: []
    })
});
