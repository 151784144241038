import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatDrawerMode, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { APP_CONFIG, AppConfig } from 'src/app/shared/models/config';
import { AppPath } from 'src/app/shared/services/app-path.service';
import { SidenavMode } from '../../../shared/models/navbar';
import { RouteMenu } from '../../../shared/models/routes';

@Component({
    selector: 'app-private-layout',
    templateUrl: './private-layout.component.html',
    styleUrls: ['./private-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class')
    className = 'main-private';

    @ViewChild('sidenav', { static: false }) sidenav: MatSidenav | undefined;
    @ViewChild('matSidenavContent', { static: true }) matSidenavContent: MatSidenavContent | undefined;

    public activeSidenavMode: MatDrawerMode = SidenavMode.default;

    public menuItems: RouteMenu[];

    private sidenavSubscription: Subscription | undefined;

    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private appPath: AppPath
    ) {
        this.appConfig.debug && console.log('buildMenu');
        this.menuItems = this.appPath.getTopMenuLinks();
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.sidenavSubscription?.unsubscribe();
    }

    ngAfterViewInit() {
        this.sidenavSubscription = this.sidenav?.openedChange.subscribe(() => {
            this.sidenav && this.handleScrollBlock();
        });
    }

    private handleScrollBlock() {}

    public checkExactRouteMatch(routeMenu: RouteMenu) {
        return routeMenu?.extra?.pathMatch == 'full';
    }
}
