import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ResponseApiListData } from './../models/general/response-api-list-data';
import { AlertService } from './../services/alert.service';
import { FormControls } from '../models/form/form-controls';
import { ErrorApi } from '../models/general/error-api';

@Injectable({
    providedIn: 'root'
})
export class Helper {
    private units = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];

    public loadingSrc = `data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==`;

    private defaultResponse: Readonly<ResponseApiListData<any>> = Object.freeze({
        status: true,
        statusCode: 1,
        data: null,
        total: 0,
        totalPage: 0,
        message: '',
        errors: null
    });

    constructor() {}

    public formatError(errors: ErrorApi[] | undefined, field: boolean = false): string[] | undefined {
        if (!errors?.length) {
            return undefined;
        }

        const errorMessage: string[] = [];

        errors.forEach((err: ErrorApi) => {
            if (err.field === 'exception') {
                console.log(err.message);
                return;
            }

            if (!field) {
                errorMessage.push(...err.message);
                return;
            }

            const msg = err.message?.map((x) => err.field + ': ' + x);
            errorMessage.push(...msg);
        });

        return errorMessage;
    }

    public setApiErrorsToForm(errors: ErrorApi[], f: FormControls) {
        if (!errors?.length || !f) {
            return;
        }

        errors.forEach((err: ErrorApi) => {
            if (err.field === 'exception') {
                console.log(err.message);
                return;
            }

            if (typeof f[err.field] === 'undefined') {
                return;
            }

            f[err.field].setErrors({ custom: true });

            const msg = err.message.reduce((prev, current) => (prev = prev + ' <br> ' + current));

            Object.defineProperty(f[err.field], 'msg', {
                value: msg,
                writable: true
            });
        });
    }

    public setApiErrorsToMultiForm(
        errors: ErrorApi[],
        f: Array<UntypedFormGroup>,
        prefix: string = '',
        matchField: string = ''
    ) {
        if (errors && errors.length && f && f.length) {
            errors.forEach((err: ErrorApi) => {
                if (err.field && err.field.length && err.field !== 'exception') {
                    if (err.field.indexOf('.') > 0) {
                        // descriptions.1.slug
                        const fields = err.field.replace(prefix + '.', '').split('.');
                        const matchFieldVal = fields[0];
                        const field = fields[1];

                        if (field != matchField) {
                            const fg: UntypedFormGroup | undefined = f.find(
                                (value: UntypedFormGroup) => value.controls?.[matchField]?.value == matchFieldVal
                            );
                            if (fg) {
                                const control = fg.controls[field];
                                if (control) {
                                    control.setErrors({ custom: true });
                                    const msg = err.message.reduce(
                                        (prev, current) => (prev = prev + ' <br> ' + current)
                                    );

                                    Object.defineProperty(control, 'msg', {
                                        value: msg,
                                        writable: true
                                    });
                                }
                            }
                        }
                    }
                }
            });
        }
    }

    public showOtherErrors(
        errors: ErrorApi[],
        f: FormControls,
        consoleExceptions: boolean = true,
        showField: boolean = false,
        alertService: AlertService
    ): void {
        if (!errors?.length || !f) {
            return;
        }

        const otherErrors: ErrorApi[] = [];

        errors.forEach((err: ErrorApi) => {
            if (err.field === 'exception') {
                consoleExceptions && console.log(err.message);
                return;
            }

            if (typeof f[err.field] === 'undefined') {
                otherErrors.push(err);
            }
        });

        if (!otherErrors.length) {
            return;
        }

        const errorMessage = this.formatError(otherErrors, showField);

        if (!errorMessage?.length) {
            return;
        }

        alertService.error(errorMessage, false, false);
    }

    public scrollTop() {
        setTimeout(() => {
            const element = document.querySelector('html');
            if (element?.scrollTop) {
                element.scrollTop = 0;
            }
        }, 300);
    }

    public last(array: Array<any>): any {
        return array[array.length - 1];
    }

    public cutText(text: string, max: number = 200, displayHellip: boolean = true): string {
        return text.substr(0, max - 1) + (text.length > max ? (displayHellip ? '&hellip;' : '...') : '');
    }

    public getBaseUrl(): string {
        const path = window.location.origin
            ? window.location.origin
            : window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

        if (path && path.length > 0) {
            return path;
        }

        return '';
    }

    public getPath(url: string): string {
        if (url) {
            let path: string = decodeURIComponent(url);
            if (url.indexOf('?') > -1) {
                path = url.substring(0, url.indexOf('?'));
            }
            return path;
        }

        return '';
    }

    public getQueryParams(url: string): Record<string, string> | undefined {
        let params: Record<string, string> | undefined;
        if (url.indexOf('?') > -1) {
            params = {};
            const querystring = url.substring(url.indexOf('?') + 1).split('&');
            // march and parse
            for (let i = querystring.length - 1; i >= 0; i--) {
                const pair = querystring[i].split('=');
                params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
        }
        return params;
    }

    public closestInteger(a: number, b: number): number {
        const c1 = a - (a % b);
        const c2 = a + b - (a % b);
        if (a - c1 > c2 - a) {
            return c2;
        } else {
            return c1;
        }
    }

    public generatePageSizeOptions(total: number, pageSizeOptions: number[], pageSize: number): number[] {
        if (total < this.last(pageSizeOptions)) {
            pageSizeOptions = pageSizeOptions.filter((x) => x <= total);
        }

        return pageSizeOptions;
    }

    public handleScrollBlock(type: 'set' | 'remove'): void {
        const element = document.querySelector('html');
        if (element) {
            switch (type) {
                case 'remove':
                    element.classList.contains('cdk-global-scrollblock') &&
                        element.classList.remove('cdk-global-scrollblock');
                    break;

                case 'set':
                    !element.classList.contains('cdk-global-scrollblock') &&
                        element.classList.add('cdk-global-scrollblock');
                    break;
            }
        }
    }

    public deepClone(obj: any) {
        // return value is input is not an Object or Array.
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        let clone: any;

        if (Array.isArray(obj)) {
            clone = obj.slice(); // unlink Array reference.
        } else {
            clone = Object.assign({}, obj); // Unlink Object reference.
        }

        let keys = Object.keys(clone);

        for (let i = 0; i < keys.length; i++) {
            clone[keys[i]] = this.deepClone(clone[keys[i]]); // recursively unlink reference to nested objects.
        }

        return clone; // return unlinked clone.
    }

    public checkStaticMetaPages(path?: string): boolean {
        if (!path) {
            return false;
        }

        if (path.indexOf('/torrent/') === -1 && path.indexOf('/wiki/') === -1 && path.indexOf('/user/') === -1) {
            return true;
        }

        return false;
    }

    public getSize(sizeStr?: string | number, levels: number = 2): string {
        if (!sizeStr) {
            return '';
        }

        sizeStr = sizeStr.toString();

        if (['-', '∞', 'hidden'].includes(sizeStr)) {
            return sizeStr;
        }

        let size = +sizeStr;

        let steps = 0;
        for (steps = 0; Math.abs(size) >= 1024; steps++) {
            size /= 1024;
        }

        if (steps >= 4) {
            levels++;
        }

        return size.toFixed(levels) + this.units[steps];
    }

    public getClassColor(userClass?: string | number): string {
        return '';
    }

    public getDefaultErrorResponse = <T>(errors: string[]) => {
        const res = this.deepClone(this.defaultResponse) as ResponseApiListData<T>;
        res.status = false;
        res.errors = errors;
        return res;
    };
}
