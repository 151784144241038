import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app/app.module';
import { APP_CONFIG, AppConfig, DefaultAppConfig } from './app/shared/models/config';
import { AppConfigProvider } from './app/shared/providers/app-config.provider';
import { environment } from './environments/environment';

const configUrl = '/config' + (!!environment.env ? '.' + environment.env : '') + '.json' + '?t=' + new Date().getTime();

function load(config: DefaultAppConfig | undefined) {
    const appConfig = new AppConfigProvider(config);

    if (appConfig.production) {
        enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: appConfig }])
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
}

fetch(configUrl)
    .then(async (res) => {
        let config: AppConfig | undefined;
        try {
            config = await res.json();
        } catch (err) {}

        load(config);
    })
    .catch((err) => {
        console.error('Failed to load App', err);
    });
