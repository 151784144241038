import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../models/config';
import { PostMessageEvent } from '../../models/iframe/post-message-event';
import { AuthenticationService } from '../../services/authentication.service';
import { GlobalEventsService } from '../../services/global-events.service';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class IframeComponent implements OnInit, AfterViewInit, OnDestroy {
    public loading = true;
    public error = false;
    private defaultHeight: number = 900;
    public url: SafeResourceUrl;
    public returnUrl: string = '';

    public errorTexts: string[] | undefined;

    @ViewChild('iframe', { static: true }) iframe!: ElementRef;

    @Input() page: string = '';
    @Input() disableUrlTracking: boolean = false;

    private loginCount: number = 0;

    private customSubscription: Subscription | undefined;

    constructor(
        private sanitizer: DomSanitizer,
        private authenticationService: AuthenticationService,
        private changeDetectorRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private globalEventsService: GlobalEventsService,
        private helperService: HelperService,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;

        if (!this.disableUrlTracking && queryParams?.['iframeUrl']) {
            this.returnUrl = queryParams['iframeUrl'];
        }

        this.customSubscription = this.globalEventsService.customSubject.subscribe((type: string) => {
            switch (type) {
                case 'window-size':
                case 'alert-global':
                    // this.helper.checkIframeSize(this.iframe);
                    break;

                case 'iframe-reload':
                    this.checkAuth(true);
                    break;

                case 'dark-mode':
                    this.checkAuth(true);
                    break;
            }
        });

        this.checkAuth();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.removeEventListenerIframe();
        this.customSubscription?.unsubscribe();
    }

    public onReload() {
        this.checkAuth(true);
    }

    private async checkAuth(force: boolean = false) {
        this.appConfig.debug && console.log('checkAuth');

        this.loading = true;
        this.error = false;
        this.removeEventListenerIframe();

        if (!this.returnUrl) {
            this.returnUrl = this.page;
        }

        const authResponse = await this.authenticationService.isGazelleAuthenticated(force);

        if (!authResponse.status) {
            this.errorTexts = authResponse.data;
            this.error = true;
            this.loading = false;
            this.detectChanges(false);

            return;
        }

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.gazelleUrl + this.returnUrl);

        // this.checkIframeSize();
        this.addEventListenerIframe();
        this.error = false;
        this.detectChanges(undefined);
    }

    private addEventListenerIframe() {
        window.addEventListener('message', this.handleReceiveMessage, false);
    }

    private removeEventListenerIframe() {
        window.removeEventListener('message', this.handleReceiveMessage, false);
    }

    private updateUrl(iframeUrl: string) {
        if (this.disableUrlTracking) {
            return;
        }

        this.returnUrl = iframeUrl;
        this.helperService.updateUrl({ iframeUrl }, true);
    }

    public handleReceiveMessage = (event: PostMessageEvent) => {
        if (event?.data?.sourceId !== 'bhd-gazelle') {
            return;
        }

        this.appConfig.debug && console.log('postMessage:', event.data);

        switch (event.data.message) {
            case 'iframe-url':
                if (event.data.data) {
                    this.updateUrl(event.data.data);
                }
                break;

            case 'request-login':
                if (this.loginCount < 2) {
                    this.loginCount++;
                    if (event.data.data) {
                        this.updateUrl(event.data.data);
                    }
                    this.checkAuth();
                }
                break;

            case 'iframe-loading':
                this.loading = true;
                setTimeout(() => {
                    this.checkIframeSize();
                    this.detectChanges(false);
                }, 20000);
                break;

            case 'iframe-loaded':
                this.detectChanges(false);
                break;

            case 'failed-login':
                console.log('failed-login');
                this.detectChanges(false);
                break;

            case 'iframe-height':
                clearTimeout(this.timer);
                this.timer = setTimeout(() => this.checkIframeSize(event.data.data), 100);
                break;
        }
    };

    private timer: number | undefined = undefined;

    private sendMessage(message: string, data: any) {
        const msgObj = {
            sourceId: 'bhd-portal',
            message: message,
            data: data
        };

        this.iframe?.nativeElement?.postMessage(msgObj, '*');
    }

    private checkIframeSize(height: number = 0) {
        const main = document.getElementById('main-content');
        const footer = document.getElementById('footer-container');

        const offset =
            window.innerHeight -
            (window.innerWidth >= 600 ? 64 : 56) -
            (main ? main.offsetTop : 0) -
            (footer ? footer.offsetHeight : 0);

        const minHeight = offset > 0 ? offset : this.defaultHeight;

        height = height > 0 || height >= offset ? height : minHeight;

        this.appConfig.debug && console.log('offset', offset, 'minHeight', minHeight, 'height', height);

        this.iframe.nativeElement.style.height = height.toString() + 'px';

        this.detectChanges(undefined);
    }

    private detectChanges(loading?: boolean) {
        if (typeof loading !== 'undefined') {
            this.loading = loading;
        }
        this.changeDetectorRef.markForCheck();
    }
}
