import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '../models/config';

@Injectable({
    providedIn: 'root'
})
export class BrowserStorageService {
    constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

    public setToken(token: string, keepLogged?: boolean): void {
        if (!token.length) {
            return;
        }

        if (!keepLogged) {
            sessionStorage.setItem(this.appConfig.browserStorage.authToken, token);
            return;
        }

        localStorage.setItem(this.appConfig.browserStorage.authToken, token);
    }

    public removeToken(): void {
        localStorage.removeItem(this.appConfig.browserStorage.authToken);
        sessionStorage.removeItem(this.appConfig.browserStorage.authToken);
    }

    public getToken(): string | null {
        const token =
            sessionStorage.getItem(this.appConfig.browserStorage.authToken) ??
            localStorage.getItem(this.appConfig.browserStorage.authToken);

        return token;
    }

    public setDarkMode(darkMode: boolean) {
        localStorage.setItem(this.appConfig.browserStorage.darkMode, darkMode ? '1' : '0');
    }

    public getDarkMode(): boolean {
        const darkModeStr = localStorage.getItem(this.appConfig.browserStorage.darkMode);

        return darkModeStr === '1';
    }
}
