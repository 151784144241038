import { Pipe, PipeTransform } from '@angular/core';

import { Helper } from '../helpers/helper';

@Pipe({ name: 'getSize' })
export class GetSizePipe implements PipeTransform {
    constructor(private helper: Helper) {}

    public transform(value?: string | number): string {
        return this.helper.getSize(value);
    }
}
