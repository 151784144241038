import { APP_INITIALIZER } from '@angular/core';

import { Repositories } from './repositories';
import { Helper } from '../helpers/helper';
import { AppInitializerProvider } from '../providers/app-initializer-provider';
import { SettingRepository } from '../repositories/setting.repository';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { BrowserStorageService } from '../services/browser-storage.service';
import { FirstLoadService } from '../services/first-load.service';
import { GlobalEventsService } from '../services/global-events.service';
import { HelperService } from '../services/helper.service';
import { RoutingProviderService } from '../services/routing-provider.service';
import { SettingsProviderService } from '../services/settings-provider.service';

export const RootServices = [
    ...Repositories,
    // ...ConfigProviders,
    GlobalEventsService,
    BrowserStorageService,
    SettingsProviderService,
    RoutingProviderService,
    FirstLoadService,
    Helper,
    AuthenticationService,
    AlertService,
    HelperService,
    {
        provide: APP_INITIALIZER,
        useFactory: (provider: AppInitializerProvider) => () => provider.load(),
        deps: [
            //
            AppInitializerProvider,
            SettingsProviderService,
            RoutingProviderService,
            FirstLoadService,
            SettingRepository
        ],
        multi: true
    }
];
