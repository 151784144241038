import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthLoginForm, UserContext } from '../models/auth';
import { APP_CONFIG, AppConfig } from '../models/config';
import { ErrorApi, ResponseApi } from '../models/general';

@Injectable({
    providedIn: 'root'
})
export class AuthRepository {
    private apiUrl: string = this.appConfig.getApiUrl(this.appConfig.apis.auth);

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {}

    public login(login: AuthLoginForm) {
        return this.http.post<ResponseApi<UserContext | ErrorApi[]>>(`${this.apiUrl}/login`, login);
    }

    public checkToken() {
        return this.http.post<ResponseApi<UserContext | ErrorApi[]>>(`${this.apiUrl}/check`, {});
    }

    public logout() {
        return this.http.post<ResponseApi<string>>(`${this.apiUrl}/logout`, {});
    }

    public gazelleLogin(token: string, darkMode: boolean) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(
            `${this.appConfig.gazelleUrl}/autologin.php`,
            {
                token,
                darkMode
            },
            {
                withCredentials: true
            }
        );
    }
}
