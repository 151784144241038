import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

import { AlertType } from '../../models/alert/alert-type';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertMessageComponent implements OnChanges {
    @Input() type: AlertType = '';
    @Input() dismissible: boolean = false;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter<void>();

    public hide = false;

    ngOnChanges(changes: SimpleChanges): void {
        let changed = false;

        Object.keys(changes).forEach((prop) => {
            if (changes[prop].currentValue !== changes[prop].previousValue) {
                changed = true;
            }
        });

        if (changed) {
            this.hide = false;
        }
    }

    onClose() {
        this.close.emit();
        this.hide = true;
    }
}
