import { LayoutType } from './layout-type';

export function getDefaultRedirect(layout: LayoutType) {
    const redirect = { path: '**', redirectTo: '' };

    switch (layout) {
        case 'private':
            redirect.redirectTo = '/';
            break;
        case 'noLayout':
        case 'public':
        default:
            redirect.redirectTo = '/public';
            break;
    }

    return redirect;
}
