import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { range } from 'lodash';

import { AppPath } from './app-path.service';
import { GlobalEventsService } from './global-events.service';
import { RoutingProviderService } from './routing-provider.service';
import { SettingsProviderService } from './settings-provider.service';
import { Helper } from '../helpers/helper';
import { APP_CONFIG, AppConfig } from '../models/config';

@Injectable({
    providedIn: 'root'
})
export class HelperService extends Helper {
    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private appPath: AppPath,
        private globalEventsService: GlobalEventsService,
        private settingsProviderService: SettingsProviderService,
        private routingProviderService: RoutingProviderService,
        private titleService: Title,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super();
    }

    public fillRecord<T>(size: number, value: T) {
        return range(0, size).reduce(
            (acc, index) => {
                acc[index] = value;
                return acc;
            },
            {} as Record<number, T>
        );
    }

    public sendIframeReload(name?: string) {
        this.globalEventsService.customSubject.next('iframe-reload');
    }

    public checkOnline(): boolean {
        const isOnline = window?.navigator?.onLine ?? false;
        const settings = this.settingsProviderService.getSettings();
        const isLive = this.settingsProviderService.getSettingBool('isLive');
        const routes = this.routingProviderService.getRoutes();

        if (isOnline && (!settings || !isLive || !routes)) {
            return false;
        }

        return true;
    }

    public setTitle(name: string) {
        const title = (!!name?.length ? name + ' | ' : '') + this.appConfig.title;

        this.titleService.setTitle(title);
    }

    public updateUrl(newParams: Record<string, any>, replaceUrl: boolean = false, fragment?: string) {
        this.router.navigate([], {
            replaceUrl,
            queryParams: {
                ...this.route.snapshot.queryParams,
                ...newParams
            },
            fragment: fragment ?? this.route.snapshot.fragment ?? undefined
        });
    }

    // default routes
    public getDefaultMaintenance() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMaintenance(), '/maintenance');
    }

    public getDefaultHome(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultHome(), '/');
    }

    public getDefaultLogin(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultLogin());
    }

    public getDefaultRegister(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRegister());
    }

    public getDefaultRecover(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRecover());
    }

    public getDefaultActivationEmail(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultActivationEmail());
    }

    public getDefaultMyAccount(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMyAccount());
    }

    public getDefaultUserTorrentsSeeding(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultUserTorrentsSeeding());
    }

    public getDefaultUserTorrentsLeeching(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultUserTorrentsLeeching());
    }

    public getDefaultUserView(userId: number): string[] {
        return [this.appPath.getRoutePath(this.appPath.getDefaultUserView()) + userId.toString()];
    }

    public getDefaultSeries(id: number): string[] {
        return [this.appPath.getRoutePath(this.appPath.getDefaultSeries()) + '/' + id.toString()];
    }

    public getDefaultTorrent(groupId: number, torrentId?: number | undefined): string[] {
        if (torrentId && torrentId > 0) {
            return [
                this.appPath.getRoutePath(this.appPath.getDefaultTorrent()) +
                    '/' +
                    groupId.toString() +
                    '/' +
                    torrentId.toString()
            ];
        }

        return [this.appPath.getRoutePath(this.appPath.getDefaultTorrent()) + '/' + groupId.toString()];
    }
}
