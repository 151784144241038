import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertModel } from '../../models/alert/alert-model';
import { AlertService } from '../../services/alert.service';
import { GlobalEventsService } from '../../services/global-events.service';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription?: Subscription;
    public message?: AlertModel;
    private timeout?: number;

    @Input() global: boolean = false;
    @Input() dismissible: boolean = true;

    constructor(
        private alertService: AlertService,
        private globalEventsService: GlobalEventsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe((message: AlertModel) => {
            if (!message || message.global !== this.global) {
                return;
            }

            if (message.type === 'reset') {
                this.message = undefined;
                this.triggerChange();
                return;
            }

            this.message = message;

            clearTimeout(this.timeout);
            if (message.timeout) {
                const handler: TimerHandler = () => {
                    this.message = undefined;
                    this.triggerChange();
                };
                this.timeout = setTimeout(handler, 10000);
            }

            this.triggerChange();
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    public onClose() {
        setTimeout(() => {
            this.message = undefined;
            this.triggerChange();
            this.triggerClose();
        }, 500);
    }

    private triggerChange() {
        this.changeDetectorRef.markForCheck();

        if (!this.global) {
            return;
        }

        setTimeout(() => {
            this.globalEventsService.customSubject.next('alert-global');
        }, 250);
    }

    private triggerClose() {
        if (!this.global) {
            return;
        }

        setTimeout(() => {
            this.globalEventsService.customSubject.next('alert-global-closed');
        }, 250);
    }
}
