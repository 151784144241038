import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { catchError, distinctUntilChanged, filter, map, Observable, of, retry, switchMap, timer } from 'rxjs';

import { APP_CONFIG, AppConfig } from 'src/app/shared/models/config';
import { UserRepository } from 'src/app/shared/repositories/user.repository';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { GlobalEventsService } from 'src/app/shared/services/global-events.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { UserStatsData } from './models';

@Component({
    selector: 'app-ratio-bar',
    templateUrl: './ratio-bar.component.html',
    styleUrls: ['./ratio-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatioBarComponent implements OnInit {
    public userStatsData$: Observable<UserStatsData | null>;

    constructor(
        public helperService: HelperService,
        private authenticationService: AuthenticationService,
        private userRepository: UserRepository,
        private globalEventsService: GlobalEventsService,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {
        this.userStatsData$ = this.globalEventsService.logged.pipe(
            map((logged) => logged && logged.isInitialized && logged.loggedIn),
            distinctUntilChanged(),
            switchMap((isInitAndLogged) =>
                !isInitAndLogged
                    ? of(null)
                    : timer(0, this.appConfig.userStatsInterval).pipe(
                          switchMap(() =>
                              this.userRepository.getUserStats().pipe(
                                  retry(2),
                                  map((userStatsRes) => {
                                      const userContext = this.authenticationService.getUserContext();

                                      if (!userContext || userStatsRes?.statusCode !== 200) {
                                          return null;
                                      }

                                      return <UserStatsData>{
                                          ...userContext,
                                          ...userStatsRes.data,
                                          classColor: this.helperService.getClassColor(userContext.class)
                                      };
                                  }),
                                  catchError((error) => {
                                      this.appConfig.debug && console.log(error);
                                      return of(null);
                                  }),
                                  filter((x) => !!x)
                              )
                          )
                      )
            )
        );
    }

    ngOnInit(): void {}
}
