import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Helper } from '../helpers/helper';
import { APP_CONFIG, AppConfig } from '../models/config';
import { AppPath } from '../services/app-path.service';
import { GlobalEventsService } from '../services/global-events.service';
import { HelperService } from '../services/helper.service';
import { SettingsProviderService } from '../services/settings-provider.service';

@Injectable()
export class BaseComponent {
    // service injections
    protected appConfig: AppConfig;
    public helper: Helper;
    protected router: Router;
    protected route: ActivatedRoute;

    public helperService: HelperService;
    public appPath: AppPath;

    protected settingsProviderService: SettingsProviderService;
    protected globalEventsService: GlobalEventsService;

    // base vars
    public loading = true;
    public error = false;

    constructor(private injector: Injector) {
        this.helper = this.injector.get(Helper);
        this.router = this.injector.get(Router);
        this.route = this.injector.get(ActivatedRoute);
        this.helperService = this.injector.get(HelperService);
        this.settingsProviderService = this.injector.get(SettingsProviderService);
        this.appPath = this.injector.get(AppPath);
        this.globalEventsService = this.injector.get(GlobalEventsService);
        this.appConfig = this.injector.get(APP_CONFIG);
    }
}
