import { Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoPreloading, Router, RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GlobalEventsService } from './../shared/services/global-events.service';
import { HelperService } from './../shared/services/helper.service';
import { SharedModule } from './../shared/shared.module';
import { AppComponent } from './app.component';
import {
    AppModuleDeclarations,
    AppModuleRouteComponents,
    AppModuleRoutes,
    LayoutsRoutes,
    LazyLoadAppModules
} from './config';
import { VersionCheckService } from './services';
import { DEBUG } from '../shared/config/debug';
import { FirstLoadService } from '../shared/services/first-load.service';
import { RoutingProviderService } from '../shared/services/routing-provider.service';

@NgModule({
    declarations: AppModuleDeclarations,
    imports: [
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        RouterModule.forRoot(AppModuleRoutes, {
            useHash: false,
            preloadingStrategy: NoPreloading,
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledNonBlocking',
            enableTracing: environment.debug
        }),
        NgxSkeletonLoaderModule.forRoot({
            appearance: 'line',
            animation: 'progress',
            theme: {
                extendsFromRoot: true,
                'border-radius': '5px',
                height: '30px',
                'background-color': '#d1d1d1',
                'margin-bottom': 0
            },
            count: 1
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    private firstLoadSubscription: Subscription;
    private appRoutesSubscription: Subscription | undefined;

    constructor(
        @Optional() @SkipSelf() parentModule: AppModule,
        private firstLoadService: FirstLoadService,
        private routingProviderService: RoutingProviderService,
        private versionCheckService: VersionCheckService,
        private globalEventsService: GlobalEventsService,
        private helperService: HelperService,
        private router: Router,
        @Inject(DEBUG) private debug: boolean
    ) {
        this.debug && console.log('init AppModule');

        // this.firstLoadService.addLoader('pula'); // to see loading logo

        this.appRoutesSubscription = this.firstLoadService.event.subscribe((type) => {
            if (type === 'app-init') {
                this.buildRoutes();

                this.appRoutesSubscription?.unsubscribe();
            }
        });

        this.firstLoadSubscription = this.globalEventsService.firstLoaded.subscribe((loaded) => {
            if (!loaded) {
                return;
            }

            this.debug && console.log('All Loader components loaded!');

            this.firstLoadSubscription?.unsubscribe();

            if (!window) {
                return;
            }

            if (!this.checkOnline()) {
                return;
            }

            this.versionCheckService.init();
        });
    }

    private buildRoutes() {
        const routes = this.routingProviderService.buildAppModuleRoutes(
            LayoutsRoutes,
            AppModuleRouteComponents,
            LazyLoadAppModules
        );

        this.debug && console.log('AppModule routes', routes);

        if (!!routes?.length) {
            this.router.resetConfig(routes);
        }
    }

    private checkOnline(): boolean {
        if (this.helperService.checkOnline()) {
            return true;
        }

        setTimeout(() => {
            this.router.navigate(this.helperService.getDefaultMaintenance());
        }, 200);

        return false;
    }
}
