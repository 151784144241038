<mat-toolbar color="primary" class="mat-elevation-z6 fix-nav app-navigation">

    <div class="menu-top-btn">
        <button mat-icon-button (click)="toggle()">
            <mat-icon aria-label="Open side bar">menu</mat-icon>
        </button>
    </div>

    <div class="nav-brand">
        <a class="brand-logo" [routerLink]="['/']" class="logo"></a>
    </div>

    <span class="nav-spacer"></span>

    <!-- Links -->
    <mat-nav-list role="navigation" fxShow="true" fxHide.lt-md="true">
        <a matLine mat-list-item *ngFor="let menuItem of menuItems"
            [routerLink]="[menuItem.path]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: menuItem.extra?.defaultHome ?? false}"
        >
            {{ menuItem.title }}
        </a>
    </mat-nav-list>
    <!-- Links -->
    <span class="nav-spacer"></span>
    <!-- Search form -->
    <!-- <app-quick-search fxShow="true" fxHide.lt-md="true"></app-quick-search> -->

    <span class="nav-spacer"></span>

    <button mat-icon-button [matMenuTriggerFor]="appMenuStaff">
        <mat-icon>security</mat-icon>
        <!-- <mat-icon>supervised_user_circle</mat-icon> -->
    </button>
    <button mat-icon-button [matMenuTriggerFor]="appMenuUser">
        <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #appMenuStaff="matMenu" class="staff-menu">
        <div *ngFor="let menuItem of menuStaffItems">
            <a mat-menu-item [routerLink]="[menuItem.path]" (click)="handle(menuItem)">
                <app-icon [icon]="menuItem.icon"></app-icon>
                <span> {{ menuItem.title }} </span>
            </a>
        </div>
    </mat-menu>

    <mat-menu #appMenuUser="matMenu" class="user-menu">

        <app-dark-mode-toggle class="dark-mode-toggle" color="primary"></app-dark-mode-toggle>

        <div *ngFor="let menuItem of menuUserItems">
            <a mat-menu-item [routerLink]="[menuItem.path]" (click)="handle(menuItem)">
                <app-icon [icon]="menuItem.icon" [alt]="menuItem.title"></app-icon>
                <span> {{ menuItem.title }} </span>
            </a>
        </div>

    </mat-menu>
</mat-toolbar>