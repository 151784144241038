import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { BrowserStorageService } from './browser-storage.service';
import { GlobalEventsService } from './global-events.service';
import { DEBUG } from '../config/debug';
import { Helper } from '../helpers/helper';
import { AuthLoginForm } from '../models/auth/auth-login-form';
import { GazelleAuthResponse } from '../models/auth/gazelle-auth-response';
import { LoginResponse } from '../models/auth/login-response';
import { UserContext } from '../models/auth/user-context';
import { ErrorApi } from '../models/general/error-api';
import { ResponseApi } from '../models/general/response-api';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public loggedIn = false;
    public loggedInGazelle = false;
    private isInitialized = false;
    private isGazelleInitialized = false;
    private oldVal = false;

    private gazelleLoginCount = 0;

    private userContext?: UserContext;

    constructor(
        private authRepository: AuthRepository,
        private helper: Helper,
        private browserStorageService: BrowserStorageService,
        private globalEventsService: GlobalEventsService,
        @Inject(DEBUG) private debug: boolean
    ) {
        // this.firstLoadService.addLoader('login');
    }

    public login(loginForm: AuthLoginForm): Observable<LoginResponse> {
        return this.authRepository.login(loginForm).pipe(
            map((result) => this.handleSuccessLoginResponse(result, loginForm.keepLogged)),
            catchError((error: string) => this.handleErrorLoginResponse(error))
        );
    }

    private handleSuccessLoginResponse(
        result: ResponseApi<UserContext | ErrorApi[]>,
        keepLogged?: boolean
    ): LoginResponse {
        if (result?.statusCode === 200) {
            const user = <UserContext>result.data;
            this.setLoggedAfterLogin(user, keepLogged);

            return {
                status: 'ok',
                message: []
            };
        }

        this.setNotLoggedAfterLogin();

        const errors = <ErrorApi[]>result.data;

        return {
            status: 'message',
            message: errors
        };
    }

    private handleErrorLoginResponse(error: string): Observable<LoginResponse> {
        console.log(error);
        return of({
            status: 'error',
            message: [
                {
                    field: 'exception',
                    message: [error]
                }
            ]
        });
    }

    public logout(remove: boolean = false) {
        console.log('logout');
        if (remove) {
            if (this.getToken() && this.loggedIn) {
                this.authRepository.logout().subscribe();
            }

            if (!this.debug) {
                this.browserStorageService.removeToken();
            }
        }

        this.isGazelleInitialized = false;
        this.loggedInGazelle = false;

        this.setNotLoggedAfterLogin();
    }

    public isAuthenticated() {
        return new Promise<boolean>((resolve, reject) => {
            if (this.isInitialized) {
                this.sendSubject();
                resolve(this.loggedIn);
                return;
            }

            const token = this.browserStorageService.getToken();

            if (!token) {
                this.setNotLogged();
                resolve(this.loggedIn);
                return;
            }

            this.authRepository.checkToken().subscribe({
                next: (result) => {
                    if (result?.statusCode !== 200) {
                        console.log('no auth');
                        this.setNotLogged();
                        resolve(this.loggedIn);
                        return;
                    }

                    this.debug && console.log('success auth check!');

                    this.userContext = <UserContext>result.data;
                    this.browserStorageService.setToken(this.userContext.token);
                    this.setLogged();

                    resolve(this.loggedIn);
                },
                error: (error: string) => {
                    console.log('error auth', error);

                    this.setNotLogged();
                    resolve(this.loggedIn);
                }
            });
        });
    }

    public isGazelleAuthenticated(force: boolean = false): Promise<GazelleAuthResponse> {
        return new Promise((resolve, reject) => {
            const token = this.getToken();

            if (!this.isInitialized || !this.loggedIn || !token) {
                resolve({
                    status: false,
                    data: ['Not Logged In!']
                });
                return;
            }

            if (!force && this.isGazelleInitialized && this.loggedInGazelle) {
                resolve({
                    status: true
                });
                return;
            }

            if (this.isGazelleInitialized && this.gazelleLoginCount > 2) {
                resolve({
                    status: false,
                    data: ['Too many tries!']
                });
                return;
            }

            this.gazelleLoginCount++;
            const darkMode = this.browserStorageService.getDarkMode();

            this.authRepository.gazelleLogin(token!, darkMode).subscribe({
                next: (result) => {
                    if (result && result.statusCode === 200) {
                        this.debug && console.log('success auth check!');

                        this.loggedInGazelle = true;
                        this.isGazelleInitialized = true;
                        this.gazelleLoginCount = 0;

                        resolve({
                            status: true
                        });
                        return;
                    }

                    console.log('no auth');
                    this.loggedInGazelle = false;
                    this.isGazelleInitialized = true;

                    const errorsApi = <ErrorApi[]>result.data;
                    const errors = this.helper.formatError(errorsApi);

                    resolve({
                        status: false,
                        data: errors
                    });
                },
                error: (error: string) => {
                    console.log('error auth', error);

                    this.isGazelleInitialized = true;
                    this.loggedInGazelle = false;

                    resolve({
                        status: false,
                        data: ['Unknown error occured']
                    });
                }
            });
        });
    }

    private setNotLogged() {
        this.loggedIn = false;
        this.sendSubject();
    }

    private setNotLoggedAfterLogin() {
        this.userContext = undefined;
        this.browserStorageService.removeToken();

        this.isInitialized = true;
        this.loggedIn = false;

        setTimeout(() => {
            this.sendSubject();
        }, 50);
    }

    private setLogged() {
        this.loggedIn = true;
        this.sendSubject();
    }

    private setLoggedAfterLogin(user: UserContext, keepLogged?: boolean) {
        this.userContext = user;
        this.browserStorageService.setToken(user.token, keepLogged);

        this.isInitialized = true;
        this.loggedIn = true;

        setTimeout(() => {
            this.sendSubject();
        }, 50);
    }

    private sendSubject(): void {
        if (this.oldVal !== this.loggedIn || !this.isInitialized) {
            this.oldVal = this.loggedIn;

            if (!this.isInitialized) {
                this.isInitialized = true;
            }

            this.globalEventsService.logged.next({
                loggedIn: this.loggedIn,
                isInitialized: this.isInitialized,
                userId: this.getUserId()
            });

            // this.firstLoadService.event.next('login');
        }
    }

    public loginUser(user: UserContext): void {
        this.setLoggedAfterLogin(user);
    }

    public checkPermission(permission: string): boolean {
        switch (permission) {
            case 'both':
                return true;

            case 'guest':
                return !this.loggedIn;

            case 'logged':
                return this.loggedIn;

            default:
                const validate = this.userContext?.permissions?.find((p) => p == permission) != null;

                return validate;
        }
    }

    public checkPublicPermission(permission: string): boolean {
        return permission === 'guest';
    }

    public checkBothPermission(permission: string): boolean {
        return permission === 'both';
    }

    public getUsername(): string {
        if (!this.loggedIn || !this.userContext) {
            return '';
        }
        return this.userContext.username;
    }

    public getClass(): string {
        if (!this.loggedIn || !this.userContext) {
            return '';
        }
        return this.userContext.class;
    }

    public getUserId(): number {
        if (!this.loggedIn || !this.userContext) {
            return 0;
        }
        return this.userContext.id;
    }

    public getToken() {
        return this.browserStorageService.getToken();
    }

    public getUserContext() {
        return this.userContext;
    }
}
