import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanMatch,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';

import { environment } from '../../../environments/environment';
import { LayoutRouteData } from '../models/routes';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutGuard implements CanActivate, CanMatch {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isLogged = await this.authenticationService.isAuthenticated();
        const { permission } = route.data as LayoutRouteData;
        return this.check(permission, isLogged, state.url);
    }

    async canMatch(route: Route, segments: UrlSegment[]) {
        const isLogged = await this.authenticationService.isAuthenticated();
        const { permission } = route.data as LayoutRouteData;
        return this.check(permission, isLogged, segments?.join('/') ?? '');
    }

    private check(permission: LayoutRouteData['permission'], isLogged: boolean, url: string): boolean {
        switch (permission) {
            case 'logged':
                environment.debug && console.log('LayoutGuard', 'logged guard', url, isLogged);
                return isLogged;

            case 'public':
                environment.debug && console.log('LayoutGuard', 'public guard', url, !isLogged);

                return !isLogged;

            case 'both':
                environment.debug && console.log('LayoutGuard', 'both guard', url, true);
                return true;

            default:
                return false;
        }
    }
}
