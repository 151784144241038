import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-private-footer',
    templateUrl: './private-footer.component.html',
    styleUrls: ['./private-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateFooterComponent {

    @HostBinding('class')
    className = 'footer-container';

    @HostBinding('id')
    id = 'footer-container';

    public siteUrl = window.location.host;

    constructor() { }
}
