import { LoadChildrenCallback } from '@angular/router';

export const LazyLoadAppModules: Record<string, LoadChildrenCallback> = {
    ForumsModule: () => import('./../../forums/forums.module').then((m) => m.ForumsModule),
    IndexModule: () => import('./../../index/index.module').then((m) => m.IndexModule),
    LoginModule: () => import('./../../login/login.module').then((m) => m.LoginModule),
    LogsModule: () => import('./../../logs/logs.module').then((m) => m.LogsModule),
    RegisterModule: () => import('./../../register/register.module').then((m) => m.RegisterModule),
    RequestsModule: () => import('./../../requests/requests.module').then((m) => m.RequestsModule),
    StaffModule: () => import('./../../staff/staff.module').then((m) => m.StaffModule),
    Top10Module: () => import('./../../top10/top10.module').then((m) => m.Top10Module),
    TorrentsModule: () => import('./../../torrents/torrents.module').then((m) => m.TorrentsModule),
    TorrentModule: () => import('./../../torrent/torrent.module').then((m) => m.TorrentModule),
    UploadModule: () => import('./../../upload/upload.module').then((m) => m.UploadModule),
    UserModule: () => import('./../../user/user.module').then((m) => m.UserModule),
    UsersModule: () => import('./../../users/users.module').then((m) => m.UsersModule),
    WikiModule: () => import('./../../wiki/wiki.module').then((m) => m.WikiModule),
    DonateModule: () => import('./../../donate/donate.module').then((m) => m.DonateModule),
    MessagesModule: () => import('./../../messages/messages.module').then((m) => m.MessagesModule),
    CommentsModule: () => import('./../../comments/comments.module').then((m) => m.CommentsModule),
    BookmarksModule: () => import('./../../bookmarks/bookmarks.module').then((m) => m.BookmarksModule),
    FriendsModule: () => import('./../../friends/friends.module').then((m) => m.FriendsModule),
    ToolsModule: () => import('./../../tools/tools.module').then((m) => m.ToolsModule),
    ReportsModule: () => import('./../../reports/reports.module').then((m) => m.ReportsModule),
    ReportsV2Module: () => import('./../../reports-v2/reports-v2.module').then((m) => m.ReportsV2Module),
    StaffPmModule: () => import('./../../staff-pm/staff-pm.module').then((m) => m.StaffPmModule),
    RecoverModule: () => import('./../../recover/recover.module').then((m) => m.RecoverModule),
    RulesModule: () => import('./../../rules/rules.module').then((m) => m.RulesModule),
    SeriesModule: () => import('./../../series/series.module').then((m) => m.SeriesModule)
};
