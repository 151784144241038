<div class="loading-shade-relative" *ngIf="loading || error">
    <app-loading *ngIf="loading"></app-loading>
    <div class="error-div" *ngIf="error">
        <h3 *ngIf="!errorTexts?.length"> An error occured </h3>
        <div *ngIf="errorTexts">
            <div class="h3" *ngFor="let errorText of errorTexts">
                {{ errorText }}
            </div>
        </div>
        <br>
        <button mat-raised-button color="primary" (click)="onReload()">
            Reload
        </button>
    </div>
</div>

<iframe [src]="url" id="bhd-iframe" name="bhd-iframe" class="bhd-iframe" title="bhd-iframe" [hidden]="loading || error" #iframe></iframe>