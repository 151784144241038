import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-index-public',
    templateUrl: './index-public.component.html',
    styleUrls: ['./index-public.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexPublicComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }

}
