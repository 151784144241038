import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarData } from '../../models/snackbar/custom-snackbar-data';

@Component({
    selector: 'app-custom-snackbar',
    templateUrl: './custom-snackbar.component.html',
    styleUrls: ['./custom-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSnackbarComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarData,
                private snackBarRef: MatSnackBarRef<SimpleSnackBar>) {
    }

    public close() {
        this.snackBarRef.dismiss();
    }

    public go() {
        this.snackBarRef.dismiss();
        if(typeof this.data.callback === 'function') {
            this.data.callback();
        }
    }
}
