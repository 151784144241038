<div class="app-maintenance">
    <mat-card class="example-card">
        <div class="background-image"></div>
        <mat-card-content>
            <h3 class="text"> Site is under maintenance! </h3>
            <p class="text-center">
                <button mat-raised-button color="primary" (click)="onClick()">Reload</button>
            </p>
        </mat-card-content>
    </mat-card>
</div>
