<app-topnav [sidenav]="sidenav"></app-topnav>

<mat-sidenav-container class="main-container" id="main-container">

    <mat-sidenav [mode]="activeSidenavMode" [opened]="false" class="mat-elevation-z3" color="accent" #sidenav>

        <mat-nav-list role="navigation" class="nav-custom">
            <a matLine mat-list-item *ngFor="let menuItem of menuItems" class="side-line" [routerLink]="[menuItem.path]"
               routerLinkActive="active" [routerLinkActiveOptions]="{exact: checkExactRouteMatch(menuItem) }" #rla="routerLinkActive">
                <mat-icon matListIcon>{{ menuItem.icon }}</mat-icon>
                <div>{{ menuItem.title }}</div>
            </a>
        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content id="main-body" class="main-body" #matSidenavContent>

        <div class="background-image left"></div>
        <div class="background-image right"></div>

        <app-alert [global]="true" class="stick-top"></app-alert>

        <app-ratio-bar></app-ratio-bar>
        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="main-content" id="main-content">
            <router-outlet></router-outlet>
        </div>

        <app-private-footer></app-private-footer>

    </mat-sidenav-content>
</mat-sidenav-container>