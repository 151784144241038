import { Injectable } from '@angular/core';

import { FirstLoadService } from '../services/first-load.service';
import { RoutingProviderService } from '../services/routing-provider.service';
import { SettingsProviderService } from '../services/settings-provider.service';

type CustomRequest = {
    name: string;
    request: Promise<void>;
};

@Injectable({
    providedIn: 'root'
})
export class AppInitializerProvider {
    constructor(
        private settingsProviderService: SettingsProviderService,
        private routingProviderService: RoutingProviderService,
        private firstLoadService: FirstLoadService
        // private authenticationService: AuthenticationService
    ) {
        this.firstLoadService.addLoader('app-init');
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            const requests: Array<CustomRequest> = [
                { name: 'settings', request: this.settingsProviderService.load() },
                { name: 'routing', request: this.routingProviderService.load() }
                // this.authenticationService.isAuthenticated;
            ];

            Promise.allSettled(requests.map((x) => x.request))
                .then((responses) => {
                    responses?.forEach((x, index) => {
                        switch (x.status) {
                            case 'fulfilled':
                                console.log('AppInitializer', requests[index].name, 'loaded successfully');
                                break;
                            case 'rejected':
                                console.error('AppInitializer', requests[index].name, 'loaded with error');
                                break;
                        }
                    });
                })
                .finally(() => {
                    resolve();
                    this.firstLoadService.event.next('app-init');
                });
        });
    }
}
