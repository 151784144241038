import { Inject, Injectable, Type } from '@angular/core';
import { LoadChildrenCallback, Route, Routes } from '@angular/router';

import { getDefaultRedirect, LayoutType, RouteData, RouteInfo } from 'src/app/shared/models/routes';
import { DEBUG } from '../config/debug';
import { PermissionGuard } from '../guards/permission.guard';
import { Helper } from '../helpers/helper';
import { SettingRepository } from '../repositories/setting.repository';

@Injectable({
    providedIn: 'root'
})
export class RoutingProviderService {
    private routes: RouteInfo[] | null = null;

    public isLoaded = false;

    constructor(
        private settingRepository: SettingRepository,
        private helper: Helper,
        @Inject(DEBUG) private debug: boolean
    ) {}

    public load(): Promise<void> {
        this.debug && console.log('init RoutingProvider');

        return new Promise((resolve, reject) => {
            this.settingRepository.getAppRoutes().subscribe({
                next: (response) => {
                    if (response) {
                        this.routes = response;
                        this.isLoaded = true;
                        resolve();
                        return;
                    }

                    this.isLoaded = true;
                    reject();
                },
                error: (error: string) => {
                    console.log(error);
                    this.isLoaded = true;
                    reject(error);
                }
            });
        });
    }

    public getRoutes(): Array<RouteInfo> | null {
        return this.routes;
    }

    public getRoutesByModule(name: string): Array<RouteInfo> | undefined {
        return this.routes ? this.routes.filter((elem) => elem.module === name) : undefined;
    }

    public buildRoutes(
        moduleName: string,
        components?: Record<string, Type<any>>,
        modules?: Record<string, LoadChildrenCallback>
    ): Routes | undefined {
        if (!this.isLoaded) {
            return undefined;
        }

        const appRoutes = this.getRoutesByModule(moduleName);

        if (!appRoutes) {
            return undefined;
        }

        const routes: Routes = [];

        appRoutes.forEach((elem: RouteInfo) => {
            if (!elem.type || !elem.component) {
                return;
            }

            let route: Route;

            switch (elem.type) {
                case 'component':
                    const component = this.getComponent(elem.component, components);

                    if (!component) {
                        return;
                    }

                    route = {
                        path: elem.route,
                        canActivate: [PermissionGuard],
                        component: component,
                        data: this.buildRouteData(elem)
                    };

                    if (elem.extra?.pathMatch) {
                        route.pathMatch = elem.extra!.pathMatch;
                    }

                    if (elem.extra?.canMatch) {
                        route.canMatch = [PermissionGuard];
                    }

                    if (!routes.find((x) => x.path === route.path)) {
                        routes.push(route);
                    }

                    break;

                case 'module':
                    const module = this.getModule(elem.component, modules);
                    if (!module) {
                        return;
                    }

                    route = {
                        path: elem.route,
                        canActivate: [PermissionGuard],
                        loadChildren: module,
                        data: this.buildRouteData(elem)
                    };

                    if (elem.extra?.pathMatch) {
                        route.pathMatch = elem.extra!.pathMatch;
                    }

                    if (elem.extra?.canMatch) {
                        route.canMatch = [PermissionGuard];
                    }

                    routes.push(route);

                    break;
            }
        });

        if (routes.length > 0) {
            routes.push(getDefaultRedirect('public'));
        }

        return routes;
    }

    public buildAppModuleRoutes(
        layoutsRoutes: Record<LayoutType, Route>,
        components?: Record<string, Type<any>>,
        modules?: Record<string, LoadChildrenCallback>
    ): Routes | undefined {
        const appRoutes = this.getRoutesByModule('AppModule');

        if (!appRoutes) {
            return undefined;
        }

        const routesMap: Record<string, Routes> = {};

        appRoutes.forEach((elem: RouteInfo) => {
            if (!elem.type || !elem.component || !elem.layout) {
                return;
            }

            let route: Route;

            switch (elem.type) {
                case 'component':
                    const component = this.getComponent(elem.component, components);

                    if (!component) {
                        return;
                    }

                    route = {
                        path: elem.route,
                        canActivate: [PermissionGuard],
                        component: component,
                        data: this.buildRouteData(elem)
                    };

                    if (elem.extra?.pathMatch) {
                        route.pathMatch = elem.extra!.pathMatch;
                    }

                    if (elem.extra?.canMatch) {
                        route.canMatch = [PermissionGuard];
                    }

                    if (!routesMap?.[elem.layout]) {
                        routesMap[elem.layout] = [];
                    }

                    if (!routesMap?.[elem.layout]?.find((x) => x.path === route.path)) {
                        routesMap?.[elem.layout].push(route);
                    }

                    break;

                case 'module':
                    const module = this.getModule(elem.component, modules);
                    if (!module) {
                        return;
                    }

                    route = {
                        path: elem.route,
                        canActivate: [PermissionGuard],
                        // canMatch: [PermissionGuard],
                        data: this.buildRouteData(elem),
                        loadChildren: module
                    };

                    if (elem.extra?.pathMatch) {
                        route.pathMatch = elem.extra!.pathMatch;
                    }

                    if (elem.extra?.canMatch) {
                        route.canMatch = [PermissionGuard];
                    }

                    if (!routesMap?.[elem.layout]) {
                        routesMap[elem.layout] = [];
                    }

                    routesMap?.[elem.layout].push(route);

                    break;
            }
        });

        const routes = Object.keys(routesMap).map((layoutStr) => {
            const layout = layoutStr as unknown as LayoutType;
            const route: Route = this.helper.deepClone(
                typeof layoutsRoutes[layout] === 'undefined' ? layoutsRoutes['private'] : layoutsRoutes[layout]
            );

            route.children = routesMap[layout];

            route.children.push(getDefaultRedirect(layout));

            return route;
        });

        // routes.push({ ...defaultRedirect });

        return routes;
    }

    private getComponent(name: string, components?: Record<string, Type<any>>): Type<any> | undefined {
        return !!name && !!components ? components[name] : undefined;
    }

    private getModule(name: string, modules?: Record<string, LoadChildrenCallback>): LoadChildrenCallback | undefined {
        return !!name && !!modules ? modules[name] : undefined;
    }

    public getModulePath(name: string): string | null {
        const route = this.routes?.find((elem) => elem.type === 'module' && elem.component === name);

        return route?.route ?? null;
    }

    private buildRouteData(elem: RouteInfo): RouteData {
        return {
            type: elem.type,
            component: elem.component,
            permission: elem.permission,
            extra: elem.extra,
            path: elem.path,
            route: elem.route
        };
    }
}
