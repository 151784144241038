<div class="app-ratio-bar">
    <ng-container *ngIf="userStatsData$ | async as userStatsData; else loadingTemplate">
        <ul class="list-inline list">
            <li>
                <span class="badge-user">
                    <app-username
                        [url]="helperService.getDefaultMyAccount()"
                        [username]="userStatsData?.username"
                        [isDonor]="userStatsData?.donor"
                        [isWarned]="userStatsData?.warned"
                    ></app-username>
                </span>
            </li>
            <li>
                <span class="badge-user" [ngStyle]="{ color: userStatsData.classColor }">
                    <fa-icon [icon]="['fas', 'user-secret']"></fa-icon>
                    <strong> {{ userStatsData?.class }}</strong>
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'arrow-up']" class="text-green"></fa-icon>
                    Upload : {{ userStatsData?.Uploaded | getSize }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'arrow-down']" class="text-red"></fa-icon>
                    Download : {{ userStatsData?.Downloaded | getSize }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'sync-alt']" class="text-blue"></fa-icon>
                    Ratio : {{ userStatsData?.Ratio }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'exchange']" class="text-orange"></fa-icon>
                    Buffer : {{ userStatsData?.Buffer | getSize }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'upload']" class="text-green"></fa-icon>
                    <a [routerLink]="helperService.getDefaultUserTorrentsSeeding()" title="My SeedingTorrents">
                        <span class="text-blue"> Seeding:</span>
                    </a>
                    {{ userStatsData?.Seeding }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'download']" class="text-red"></fa-icon>
                    <a [routerLink]="helperService.getDefaultUserTorrentsLeeching()" title="My Leeching Torrents">
                        <span class="text-blue"> Leeching:</span>
                    </a>
                    {{ userStatsData?.Leeching }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'coins']" class="text-gold"></fa-icon>
                    <a href="#" title="My Bonus Points">
                        <span class="text-blue"> BON:</span>
                    </a>
                    {{ userStatsData?.Bonus }}
                </span>
            </li>
            <li>
                <span class="badge-user">
                    <fa-icon [icon]="['fas', 'star']" class="text-gold"></fa-icon>
                    <a href="#" title="My FL tokens">
                        <span class="text-blue"> Freeleech Tokens :</span>
                    </a>
                    {{ userStatsData?.FLTokens }}
                </span>
            </li>
        </ul>
    </ng-container>

    <ng-template #loadingTemplate>
        <ngx-skeleton-loader count="1" appearance="line" animation="progress"></ngx-skeleton-loader>
    </ng-template>
</div>
