import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Logged } from '../models/general/logged';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventsService {

    // FirstLoadService finished loading all elements
    public firstLoaded = new BehaviorSubject<boolean>(false);

    // send custom events
    public customSubject = new Subject<string>();

    // AuthenticationService user is logged event
    public logged = new BehaviorSubject<Logged>({ loggedIn: false, isInitialized: false });
}
