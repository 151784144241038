import { AuthRepository } from '../repositories/auth.repository';
import { SettingRepository } from '../repositories/setting.repository';
import { UserRepository } from '../repositories/user.repository';

export const Repositories = [
    //
    SettingRepository,
    AuthRepository,
    UserRepository
];
