import { Type } from '@angular/core';

import { ErrorComponent } from './../pages/error/error.component';
import { TestComponent } from './../pages/test/test.component';
import { DeniedComponent } from '../pages/denied/denied.component';
import { IndexPublicComponent } from '../pages/index-public/index-public.component';
import { MaintenanceComponent } from '../pages/maintenance/maintenance.component';

export const AppModuleRouteComponents: Record<string, Type<any>> = {
    MaintenanceComponent: MaintenanceComponent,
    DeniedComponent: DeniedComponent,
    IndexPublicComponent: IndexPublicComponent,
    ErrorComponent: ErrorComponent,
    TestComponent: TestComponent
};
