<ng-container *ngIf="!!faIcon; then faIconTemplate; else matIconTemplate"></ng-container>

<ng-template #faIconTemplate>
    <fa-icon [title]="alt" [icon]="faIcon!"></fa-icon>
</ng-template>

<ng-template #matIconTemplate>
    <mat-icon [title]="alt">{{icon ?? ''}}</mat-icon>
</ng-template>

