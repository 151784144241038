import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Helper } from './../../helpers/helper';
import { UserEnabledType } from '../../models/user';

@Component({
    selector: 'app-username',
    templateUrl: './username.component.html',
    styleUrls: ['./username.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsernameComponent implements OnInit {
    @Input() url: string[] | undefined;
    @Input() username: string | undefined;
    @Input() isWarned: boolean | undefined;
    @Input() isDonor: boolean | undefined;
    @Input() enabled: UserEnabledType | number | undefined;
    @Input() class: string | number | undefined;

    constructor(public helper: Helper) {}

    ngOnInit(): void {}

    public getClassColor = () => this.helper.getClassColor(this.class);
}
