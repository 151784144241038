import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';

import { APP_CONFIG, AppConfig } from 'src/app/shared/models/config';
import { RouteMenu } from 'src/app/shared/models/routes';
import { AppPath } from 'src/app/shared/services/app-path.service';

@Component({
    selector: 'app-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicLayoutComponent {
    @HostBinding('class')
    className = 'main-public';

    public title = this.appConfig.title;

    public menuItems: RouteMenu[];

    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        private appPath: AppPath
    ) {
        this.menuItems = this.appPath.getPublicMenuLinks();
    }
}
