import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BrowserStorageService } from '../../services/browser-storage.service';
import { GlobalEventsService } from '../../services/global-events.service';

@Component({
    selector: 'app-dark-mode-toggle',
    templateUrl: './dark-mode-toggle.component.html',
    styleUrls: ['./dark-mode-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DarkModeToggleComponent implements OnInit, OnDestroy {
    @Input() color: string = 'primary';

    private darkClassName = 'dark-mode';
    public toggleControl = new UntypedFormControl();

    @HostBinding('class') className = '';

    private subscription: Subscription | undefined;

    constructor(
        private overlay: OverlayContainer,
        private renderer: Renderer2,
        private browserStorageService: BrowserStorageService,
        private globalEventsService: GlobalEventsService
    ) {}

    ngOnInit(): void {
        const darkMode = this.browserStorageService.getDarkMode();
        this.toggleControl.setValue(darkMode);
        this.toggle(darkMode);

        this.subscription = this.toggleControl.valueChanges.subscribe((darkMode: boolean) => {
            this.browserStorageService.setDarkMode(darkMode);
            this.globalEventsService.customSubject.next('dark-mode');

            this.toggle(darkMode);
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private toggle(darkMode: boolean) {
        return darkMode ? this.setDarkMode() : this.setWhiteMode();
    }

    private setDarkMode() {
        this.renderer.addClass(document.body, this.darkClassName);
        document.querySelector('html')?.setAttribute('data-bs-theme', 'dark');
        this.overlay.getContainerElement().classList.add(this.darkClassName);
    }

    private setWhiteMode() {
        this.overlay.getContainerElement().classList.remove(this.darkClassName);
        document.querySelector('html')?.setAttribute('data-bs-theme', 'light');
        this.renderer.removeClass(document.body, this.darkClassName);
    }
}
