import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalEventsService } from './global-events.service';

@Injectable({
    providedIn: 'root'
})
export class FirstLoadService {
    public event = new Subject<string>();
    private loaders: Record<string, boolean> = {};
    private eventSubscription: Subscription;
    private loadedSubscription: Subscription;

    constructor(private globalEventsService: GlobalEventsService) {

        this.eventSubscription = this.event.subscribe(
            (data: string) => {
                // console.log(data);
                if(typeof this.loaders[data] !== 'undefined') {
                    environment.debug && console.log('doneLoader: ' + data);
                    this.loaders[data] = true;
                }

                if(this.loaders && this.checkLoaders()) {
                    environment.debug && console.log('All loaders done!');
                    this.globalEventsService.firstLoaded.next(true);
                }
            }
        );

        this.loadedSubscription = this.globalEventsService.firstLoaded.subscribe((loaded: boolean) => {
            if(!loaded) {
                return;
            }
            this.eventSubscription?.unsubscribe();
            this.loadedSubscription?.unsubscribe();
        });
    }

    public addLoader(name: string): void {
        environment.debug && console.log('addLoader:', name);
        if(typeof this.loaders[ name ] === 'undefined') {
            this.loaders[name] = false;
        }
    }

    private checkLoaders(): boolean {
        const loaders = {...this.loaders};
        const c1 = Object.keys(loaders);
        const c2 = c1.map((key) => loaders[key]);
        const c3 = c2.reduce((res, element) => res && element, true);

        return !!loaders && c3;
    }
}
