import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '../models/config';
import { ResponseApi } from '../models/general/response-api';
import { UserStats } from '../models/user/user-stats';

@Injectable({
    providedIn: 'root'
})
export class UserRepository {
    private apiUrl: string = this.appConfig.getApiUrl(this.appConfig.apis.user);

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {}

    public getUserStats() {
        return this.http.get<ResponseApi<UserStats>>(`${this.apiUrl}/stats`);
    }
}
