import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { BaseComponent } from 'src/app/shared/components/base.component';
import { RouteMenu } from 'src/app/shared/models/routes';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopnavComponent extends BaseComponent implements OnInit {
    @Input() sidenav: MatSidenav | undefined;

    public title: string = this.appConfig.title;

    public menuItems: RouteMenu[] = [];
    public menuUserItems: RouteMenu[] = [];
    public menuStaffItems: RouteMenu[] = [];

    constructor(
        injector: Injector,
        private authenticationService: AuthenticationService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.appConfig.debug && console.log('load menus');
        this.menuItems = this.appPath.getTopMenuLinks();
        this.menuUserItems = this.appPath.getUserTopLinks();
        this.menuStaffItems = this.appPath.getStaffTopLinks();
    }

    public toggle() {
        this.sidenav?.toggle();
    }

    public handle(menuItem: RouteMenu): void {
        if (!menuItem.extra?.defaultLogout) {
            return;
        }

        this.authenticationService.logout(true);
        this.router.navigate(this.helperService.getDefaultLogin());
    }
}
