import { Routes } from '@angular/router';

import { getDefaultRedirect } from 'src/app/shared/models/routes';
import { DeniedComponent } from './../pages/denied/denied.component';
import { LayoutsRoutes } from './layouts-routes';
import { IndexPublicComponent } from '../pages/index-public/index-public.component';
import { MaintenanceComponent } from '../pages/maintenance/maintenance.component';
import { TestComponent } from '../pages/test/test.component';

export const AppModuleRoutes: Routes = [
    {
        ...LayoutsRoutes.private,
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('../../index/index.module').then((m) => m.IndexModule)
            },
            {
                path: 'denied',
                component: DeniedComponent
            },
            getDefaultRedirect('private')
        ]
    },
    {
        ...LayoutsRoutes.public,
        children: [
            {
                path: 'public',
                component: IndexPublicComponent
            }
        ]
    },
    {
        ...LayoutsRoutes.noLayout,
        children: [
            {
                path: 'maintenance',
                component: MaintenanceComponent
            },
            {
                path: 'error',
                component: MaintenanceComponent
            },
            {
                path: 'test',
                component: TestComponent
            },
            getDefaultRedirect('noLayout')
        ]
    }
];
