import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanMatch,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';

import { DEBUG } from '../config/debug';
import { Helper } from '../helpers/helper';
import { RouteData } from '../models/routes';
import { AppPath } from '../services/app-path.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanMatch {
    constructor(
        private router: Router,
        private appPath: AppPath,
        private authenticationService: AuthenticationService,
        private helper: Helper,
        @Inject(DEBUG) private debug: boolean
    ) {}

    async canMatch(route: Route, segments: UrlSegment[]) {
        const isLogged = await this.authenticationService.isAuthenticated();
        const routeData = route.data as RouteData;
        return this.check(isLogged, routeData, segments?.join('/') ?? '');
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLogged = await this.authenticationService.isAuthenticated();
        const routeData = route.data as RouteData;
        return this.check(isLogged, routeData, state.url);
    }

    private check(isLogged: boolean, routeData: RouteData, url: string): boolean {
        this.debug && console.log('PermissionGuard', url, routeData);

        this.helper.scrollTop();

        if (this.authenticationService.checkBothPermission(routeData.permission)) {
            return true;
        }

        if (!isLogged) {
            if (!this.authenticationService.checkPublicPermission(routeData.permission)) {
                this.router.navigate(this.appPath.getRoutePathArr(this.appPath.getDefaultLogin(), '/login'), {
                    queryParams: { returnUrl: url }
                });

                return false;
            }

            return true;
        }

        if (this.authenticationService.checkPublicPermission(routeData.permission)) {
            console.log('router guard checkPublicPermission');
            this.router.navigate(this.appPath.getRoutePathArr(this.appPath.getDefaultPublic(), '/public'));
            return false;
        }

        if (!this.authenticationService.checkPermission(routeData.permission)) {
            this.router.navigate(
                this.appPath.getRoutePathArr(this.appPath.getDefaultDenied(), '/denied') /*{
                queryParams: { returnUrl: url }
            }*/
            );
            return false;
        }

        return true;
    }
}
