<ng-container *ngIf="page$ | async as page; else loadingTemplate">
    <div class="app-error">
        <mat-card class="example-card">
            <div class="background-image"></div>
            <mat-card-content>
                <app-alert-message type="danger" [dismissible]="false">
                    <h4>{{page.errorMessage}}</h4>
                </app-alert-message>
                <p class="text-center">
                    <button mat-raised-button color="primary" (click)="onClick(page.returnUrl)">Reload</button>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</ng-container>
<ng-template #loadingTemplate>
    <div class="loading-shade-relative">
        <app-loading></app-loading>
    </div>
</ng-template>

