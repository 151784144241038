<div class="col-xl-4 col-lg-4 col-md-8 col-sm-10 col-xs-12 content-wrapper">
    <div class="background-image"></div>

    <div class="top-page">
        <a [routerLink]="['/public']" class="logo">{{ title }}</a>
        <div class="flex-stretch"></div>
        <app-dark-mode-toggle color="primary"></app-dark-mode-toggle>
    </div>

    <mat-card class="app-navigation mat-elevation-z6" id="app-navigation">
        <mat-nav-list role="navigation">
            <a matLine mat-list-item *ngFor="let menuItem of menuItems" [routerLink]="[menuItem.path]" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: menuItem.extra?.defaultHome ?? false }"> {{ menuItem.title }} </a>
        </mat-nav-list>
    </mat-card>

    <div class="main-content" id="main-content">
        <router-outlet></router-outlet>
    </div>
</div>