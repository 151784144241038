import { Injectable } from '@angular/core';
import { SiteSettings } from '../models/setting/site-settings';
import { ResponseApi } from '../models/general/response-api';
import { SettingRepository } from '../repositories/setting.repository';

@Injectable({
    providedIn: 'root'
})
export class SettingsProviderService {
    public isLoaded = false;

    private settings: SiteSettings | null = null;

    constructor(private settingRepository: SettingRepository) {}

    public getSettings() {
        return this.settings;
    }

    public getSettingNumber(key: string, defaultValue: number = 0): number {
        if (this.settings?.[key]) {
            return +this.settings[key]!;
        }
        return defaultValue;
    }

    public getSettingString(key: string): string {
        if (this.settings?.[key]) {
            return this.settings[key] as string;
        }
        return '';
    }

    public getSettingBool(key: string): boolean {
        if (this.settings?.[key]) {
            return this.settings[key] as boolean;
        }
        return false;
    }

    public getSettingArray(key: string, defaultArray: boolean = false, defaultValue?: Array<any>): Array<any> | null {
        if (this.settings?.[key]) {
            return this.settings[key] as Array<any>;
        }
        return !!defaultValue ? defaultValue : defaultArray ? [] : null;
    }

    public getSettingObj<T>(key: string, defaultObj: boolean = false, defaultValue: T): T | null {
        if (this.settings?.[key]) {
            return this.settings[key] as T;
        }
        return defaultObj ? defaultValue : null;
    }

    public load(): Promise<void> {
        // console.log('init load settings');

        return new Promise((resolve, reject) => {
            this.settingRepository.getSiteSettings().subscribe({
                next: (response: ResponseApi<SiteSettings>) => {
                    if (response?.statusCode === 200) {
                        this.settings = response.data;
                    }
                    this.isLoaded = true;
                    resolve();
                },
                error: (error: string) => {
                    console.log(error);
                    this.isLoaded = true;
                    reject(error);
                }
            });
        });
    }
}
