<mat-card class="alert-message mat-elevation-z0" [class.hide]="hide" [class.dismissible]="dismissible" [ngClass]="type">

    <ng-content #content></ng-content>

    <ng-container *ngIf="dismissible">
        <button class="btn-close" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </ng-container>
</mat-card>
