import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MyCustomMaterialModule } from '../modules/material.module';

export const SharedModules: (any[] | Type<any>)[] = [
    CommonModule,
    MyCustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule
];
