import { Inject, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { ConfigProviders } from './config/config-providers';
import { DEBUG } from './config/debug';
import { Guards } from './config/guars';
import { Pipes } from './config/pipes';
import { RootServices } from './config/root-services';
import { SharedComponents } from './config/shared-components';
import { SharedDirectives } from './config/shared-directives';
import { SharedModules } from './config/shared-modules';

@NgModule({
    declarations: [
        //
        ...Pipes,
        ...SharedComponents,
        ...SharedDirectives
    ],
    imports: SharedModules,
    exports: [
        //
        ...Pipes,
        ...SharedComponents,
        ...SharedDirectives,
        ...SharedModules
    ],
    providers: [
        //
        ...ConfigProviders,
        ...Guards
    ]
})
export class SharedModule {
    constructor(@Optional() @SkipSelf() parentModule: SharedModule, @Inject(DEBUG) debug: boolean) {
        debug && console.log('init SharedModule', !!parentModule);
    }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: RootServices
        };
    }
}
