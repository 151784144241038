import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-no-layout',
    templateUrl: './no-layout.component.html',
    styleUrls: ['./no-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoLayoutComponent {

    @HostBinding('class')
    className = 'no-layout';
}
