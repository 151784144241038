import { Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { Helper } from './../helpers/helper';
import { FirstLoadService } from './first-load.service';
import { RoutingProviderService } from './routing-provider.service';
import { SettingsProviderService } from './settings-provider.service';
import { DEBUG } from '../config/debug';
import { RouteInfo } from '../models/routes/route-info';
import { RouteMenu } from '../models/routes/route-menu';

@Injectable({
    providedIn: 'root'
})
export class AppPath {
    public isLoaded = false;

    private routes: RouteInfo[] = [];
    private eventSubscription: Subscription;

    constructor(
        private settingsProviderService: SettingsProviderService,
        private firstLoadService: FirstLoadService,
        private helper: Helper,
        private routingProviderService: RoutingProviderService,
        @Inject(DEBUG) private debug: boolean
    ) {
        this.firstLoadService.addLoader('app-path');

        this.eventSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if (type === 'app-init') {
                const routes = this.routingProviderService.getRoutes();

                this.routes = routes ? this.excludeNotEnabledRoutes(routes) : [];

                this.isLoaded = true;

                this.firstLoadService.event.next('app-path');

                this.eventSubscription.unsubscribe();
            }
        });
    }

    private excludeNotEnabledRoutes(routes: RouteInfo[]): RouteInfo[] {
        return routes;
    }

    private findRouteByExtra(key: string): RouteInfo | undefined {
        const route = this.routes.find((item: RouteInfo) => item.extra?.[key]);

        if (route) {
            return this.helper.deepClone(route);
        }

        return undefined;
    }

    private findRouteByLocation(key: string): RouteInfo[] {
        return this.helper.deepClone(this.routes.filter((item: RouteInfo) => item.location?.indexOf(key) > -1));
    }

    private _findRouteByPath(path: string, exact: boolean = false): RouteInfo[] {
        let routes = this.routes.filter((item: RouteInfo) => {
            if (item.type !== 'component') {
                return false;
            }

            if (path === item.path) {
                return true;
            }

            if (!exact && path.indexOf(item.path) === 0) {
                return true;
            }

            return false;
        });

        if (!routes?.length) {
            routes = this.routes.filter((item: RouteInfo) => {
                if (item.type !== 'module') {
                    return false;
                }

                if (path === item.path) {
                    return true;
                }

                if (!exact && path.indexOf(item.path) === 0) {
                    return true;
                }

                return false;
            });
        }

        return routes.sort((a, b) => (a.path.length <= b.path.length ? 1 : -1));
    }

    public findRouteByPath(path: string, exactOnly: boolean = false): RouteInfo | undefined {
        // match exact
        let routes = this._findRouteByPath(path, true);

        // match loose
        if (!exactOnly && !routes?.length) {
            routes = this._findRouteByPath(path, false);
        }

        if (routes?.length > 0) {
            return this.helper.deepClone(this.helper.last(routes));
        }

        return undefined;
    }

    public getRoutePathArr(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string[] {
        return [routeInfo ? this.getPath(routeInfo) : defaultPath];
    }

    public getRoutePath(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string {
        return routeInfo ? this.getPath(routeInfo) : defaultPath;
    }

    public getRouteTitle(routeInfo?: RouteInfo): string {
        return routeInfo?.title ?? '';
    }

    public getPath(routeInfo: RouteInfo | undefined): string {
        return routeInfo?.path ?? '';
    }

    private findRouteByLocations(keys: string[]): RouteInfo[] {
        return this.routes.filter((item: RouteInfo) => {
            let isMatch = true;

            keys.forEach((key: string) => {
                isMatch = isMatch && item.location && item.location.indexOf(key) > -1;
            });

            return isMatch;
        });
    }

    public getDefaultUser(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUser');
    }

    public getDefaultHome(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultHome');
    }

    getDefaultPublic(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultPublic');
    }

    public getDefaultRegister(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRegister');
    }

    public getDefaultPage(slug: string | number): RouteInfo | undefined {
        const route = this.helper.deepClone(this.findRouteByExtra('defaultPage'));

        if (route && route.path) {
            for (let i in route.path) {
                route.path[i] = route.path[i].replace('{slug}', slug.toString());
            }
        }

        return route;
    }

    public getDefaultLogin(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultLogin');
    }

    public getDefaultMaintenance(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMaintenance');
    }

    public getDefaultRecover(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRecover');
    }

    public getDefaultActivationEmail(): RouteInfo | undefined {
        return this.findRouteByExtra('activationEmail');
    }

    public getDefaultDenied(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultDenied');
    }

    public getDefaultMyAccount(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMyAccount');
    }

    public getDefaultUserTorrentsLeeching(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUserTorrentsLeeching');
    }

    public getDefaultUserTorrentsSeeding(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUserTorrentsSeeding');
    }

    public getDefaultUserView(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUserView');
    }

    public getDefaultSeries(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultSeries');
    }

    public getDefaultTorrent(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultTorrent');
    }

    public getRoutes(): RouteInfo[] {
        return this.routes;
    }

    public getTopMenuLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('topMenu');

        return routes?.map((route) => this.mapMenu(route)) ?? [];
    }

    public getPublicMenuLinks(): RouteMenu[] {
        let routes = this.findRouteByLocation('publicMenu');

        const openRegistration = this.settingsProviderService.getSettingBool('openRegistration');

        if (!openRegistration) {
            routes = routes.filter((route) => !(route.extra?.defaultRegister === true));
        }

        if (routes?.length) {
            return routes.map((route) => this.mapMenu(route));
        }

        return [];
    }

    public getFooterLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('footer');

        if (routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    public getUserLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('user');

        if (routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    private mapMenu(route: RouteInfo): RouteMenu {
        return {
            path: route.path,
            title: route.title,
            icon: route.icon,
            permission: route.permission,
            extra: route.extra
        };
    }

    // public getLeftMenuLinks(group: UserGroupName): RouteMenu[] {

    // }

    public getUserTopLinks(): RouteMenu[] {
        let routes = this.findRouteByLocation('topUser');

        if (routes?.length) {
            return routes.map((route) => this.mapMenu(route));
        }

        return [];
    }

    public getStaffTopLinks(): RouteMenu[] {
        return this.findRouteByLocation('topStaff')?.map((route) => this.mapMenu(route)) ?? [];
    }

    public getTitle(url: string): string {
        if (url?.length > 0) {
            const route = this.findRouteByPath(this.getPathFromUrl(url), true);

            if (!!route?.title && !route?.extra?.defaultHome) {
                return this.getRouteTitle(route);
            }
        }
        return '';
    }

    private getPathFromUrl(url: string): string {
        const tempPath = url.split(/\?|#/g);
        return (tempPath && tempPath[0]) || '';
    }

    public checkIfFullPage(path: string | undefined): boolean {
        if (path) {
            const routeInfo = this.findRouteByPath(path);
            if (routeInfo?.extra) {
                return routeInfo.extra?.fullPage === true || routeInfo.extra?.fullPage === undefined;
            }
        }

        return true;
    }
}
