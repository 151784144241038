import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    page$ = this.route.queryParamMap.pipe(
        map((queryParams) => {
            const errorMessage = queryParams.get('error') || '';
            const returnUrl = queryParams.get('returnUrl') || '/';

            return { errorMessage, returnUrl };
        })
    );

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {}

    public onClick(url: string) {
        location.href = url;
    }
}
