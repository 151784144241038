import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HelperService } from 'src/app/shared/services/helper.service';
import { SettingsProviderService } from 'src/app/shared/services/settings-provider.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceComponent implements OnInit {
    constructor(
        private settingsProviderService: SettingsProviderService,
        private helperService: HelperService,
        private router: Router
    ) {}

    ngOnInit() {
        const isLive = this.settingsProviderService.getSettingBool('isLive');
        const settings = this.settingsProviderService.getSettings();

        if (!!settings && isLive) {
            this.router.navigate(this.helperService.getDefaultHome());
        }
    }

    public onClick() {
        location.href = '/';
    }
}
