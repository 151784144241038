import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-denied',
    templateUrl: './denied.component.html',
    styleUrls: ['./denied.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeniedComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
