import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, ObservableInput, of, throwError } from 'rxjs';

import { rewritesApi } from 'src/environments/rewrites';
import { APP_CONFIG, AppConfig } from '../models/config';
import { MapObj } from '../models/general';
import { AppPath } from '../services/app-path.service';
import { AuthenticationService } from '../services/authentication.service';
import { BrowserStorageService } from '../services/browser-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private appPath: AppPath,
        private browserStorageService: BrowserStorageService,
        private authenticationService: AuthenticationService,
        private router: Router,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appConfig.debug) {
            const urls = [this.appConfig.apiUrl];

            if (Object.keys(rewritesApi)?.length) {
                Object.keys(rewritesApi).forEach((key) => urls.push((rewritesApi as any)[key]));
            }

            if (urls.every((url) => request.url.indexOf(url) === -1)) {
                return next.handle(request);
            }
        } else if (request.url.indexOf(this.appConfig.apiUrl) === -1) {
            return next.handle(request);
        }

        // add authorization header with jwt token if available
        const token = this.browserStorageService.getToken();

        // console.log(request, request.reportProgress);

        const headers: MapObj<string> = {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        };

        headers['Content-Type'] = 'application/json';

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        request = request.clone({
            headers: new HttpHeaders(headers)
        });

        return next.handle(request).pipe(
            catchError<HttpEvent<any>, ObservableInput<any>>((err) => {
                if (err.status === 401 || err.status === 403) {
                    if (
                        request.url.indexOf('/auth/check') === -1 &&
                        request.url.indexOf('/auth/logout') === -1 &&
                        request.url.indexOf('/setting/site') === -1
                    ) {
                        // auto logout if 401 response returned from api
                        this.authenticationService.logout(false);

                        setTimeout(() => {
                            this.router.navigate(
                                this.appPath.getRoutePathArr(this.appPath.getDefaultLogin(), '/login'),
                                {
                                    queryParams: { returnUrl: this.router.url }
                                }
                            );
                        }, 50);
                        return of(null);
                    }
                }

                // if(request.url.indexOf('/torrent/download/') > -1) {
                //     throwError(() => err);
                //     return;
                // }

                console.log('error from api jwt!');
                console.log(request.urlWithParams);
                console.log(err);

                return throwError(() => {
                    const error: string = (err && err.error && err.error.message) || err.message || err.statusText;
                    return error;
                });
            })
        );
    }
}
