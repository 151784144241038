import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isArray } from 'lodash';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit {
    @Input() icon: string | string[] | IconProp | undefined;
    @Input() fallback: string | string[] | undefined;
    @Input() alt: string = '';

    public faIcon: IconProp | undefined;

    constructor() {}

    ngOnInit(): void {
        if (!this.icon && !this.fallback) {
            this.icon = this.fallback;
        }

        this.alt = this.alt ?? '';

        this.faIcon = isArray(this.icon) ? (this.icon as IconProp) : undefined;
    }
}
